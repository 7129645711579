import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Header from './components/Header/Header'
import HeaderLinks from './components/Header/HeaderLinks'
import Footer from './components/Footer/Footer'

import Home from './views/home'
import Contact from './views/Contact'
import Terms from './views/Terms'
import Privacy from './views/Privacy'

import Resource from './views/Resource'
import ResourceDetails from './views/ResourceDetails'
import Features from './views/Features'
import FeaturesDetails from './views/FeaturesDetails'
//import Community from './views/Community'
////import CommunityDetails from './views/CommunityDetails'
//import Plans from './views/Plans'
//import PlansDetails from './views/PlansDetails'

import NotFound404 from './views/NotFound404'

function App(props) {

  const { ...rest } = props;
  return (
    <BrowserRouter>
      <div className="App" style={{paddingTop:"80px"}}>
        <Header
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/terms' component={Terms} />
            <Route exact path='/privacy' component={Privacy} />

            <Route exact path='/resource/' component={Resource} />
            <Route exact path='/features/' component={Features} />
            {/*<Route exact path='/community/' component={Community} />
            <Route exact path='/plans/' component={Plans} />*/}

            <Route exact path='/resource/:id' component={ResourceDetails} />
            <Route exact path='/features/:id' component={FeaturesDetails} />
            {/*<Route exact path='/community/:id'  component={CommunityDetails} />
            <Route exact path='/plans/:name' component={PlansDetails} />*/}

            <Route component={NotFound404}  status={404}/>
          </Switch>
          <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
