import React from 'react'

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";

import styles from "../../assets old/jss/material-kit-react/components/headerStyle.js";

import HeaderLinks from './HeaderLinks'
import { ListItem } from '@material-ui/core';

import { NavLink } from 'react-router-dom'

const useStyles = makeStyles(styles);

const Header = (props) => {

    const {user} = props
    const { leftLinks } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const classes = useStyles();
    //var user = firebase.auth().currentUser;
    //const links = user == null ? <SignedInLinks />  :  <SignedOutLinks />;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    React.useEffect(() => {
      if (props.changeColorOnScroll) {
        window.addEventListener("scroll", headerColorChange);
      }
      return function cleanup() {
        if (props.changeColorOnScroll) {
          window.removeEventListener("scroll", headerColorChange);
        }
      };
    });


    const headerColorChange = () => {
      const windowsScrollTop = window.pageYOffset;
      if (windowsScrollTop > 30) 
      {
        document.body.getElementsByTagName("header")[0].classList.remove(classes["transparent"]);
        document.body.getElementsByTagName("header")[0].classList.add(classes["white"]);
        document.getElementById("headerLogoImage").src ="/images/logo/Logo.png";
      } 
      else 
      {
        document.body.getElementsByTagName("header")[0].classList.add(classes["transparent"]);
        document.body.getElementsByTagName("header")[0].classList.remove(classes["white"]);
        document.getElementById("headerLogoImage").src ="/images/logo/Logo.png";
      }
    };
    return (
        <AppBar className={classes.fixed, classes.appBar, classes.transparent}>
          <Toolbar>
            
            <Link component={NavLink} to="/" className={classes.title}>
              <img id="headerLogoImage" className={classes.imageIcon} style={{width:"40px"}} src="/images/logo/Logo.png"/>
            </Link>
            
            <div className={classes.flex}>
              <Hidden smDown implementation="css">
                <HeaderLinks/>
              </Hidden>
              
              <Hidden mdUp implementation="css">
                <IconButton
                  color="inherit"
                  aria-label="open Menu"
                  onClick={handleDrawerToggle}
                >
                  <Menu />
                </IconButton>
              </Hidden>
            </div>

            <Button id="LoginIdButtonHeader"  href="https://basic.jected.com/"  variant="contained" className={classes.Button}>dashboard</Button>

          </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            <HeaderLinks/>
            
            <Button id="LoginIdButtonHeader" fullWidth={true} href="https://basic.jected.com/"  variant="contained" className={classes.Button}>dashboard</Button>
          </div>
        </Drawer>
        </Hidden>
    </AppBar>
    )
}






export default (Header)